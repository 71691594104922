import { PGTypeCheckClientList, PGTypeNoCheckClientList, PriceGuaranteeStatusEnum } from './const';

export const checkClientByPGType = (priceGuaranteeType) => {
  if (PGTypeNoCheckClientList.includes(priceGuaranteeType)) {
    return false;
  } else if (PGTypeCheckClientList.includes(priceGuaranteeType)) {
    return true;
  }
  return null;
};

export const isPgCompleted = (priceGuarantee) => {
  return priceGuarantee.status === PriceGuaranteeStatusEnum.COMPLETED;
};
